import modal from './modal.js'
import mobilemenu from './mobilemenu.js'
import masonry from 'masonry-layout/masonry.js'

const m = new modal()
m.init()

const mm = new mobilemenu()
mm.init()

const elem = document.querySelector('.masonry');
if (elem) {
  const msnry = new masonry( elem, {
    itemSelector: '.masonry__item'
  })
}
