import u from './utility.js'

export default function() {

  'use strict'

  // Init variables
  var api = {}
  var settings

  // Default settings
  var defaults = {
    classTrigger: 'menu__toggle',
    classTarget: 'menu',
    classToggle: 'd_none'
  }

  // Private functions
  var run = function() {
    var trigger = event.target.closest('.' + settings.classTrigger)

    if (trigger) {
      u.toggleClass(trigger.nextSibling, settings.classToggle)
      event.preventDefault()
    }
  }

  // Public functions
  api.init = function(options) {
    api.destroy()
    settings = u.extend( defaults, options || {} )
    document.addEventListener('click', run, false)
  }

  api.destroy = function() {
    settings = null
    document.removeEventListener('click', run, false)
  }

  // Return the application program interface
  return api
}
